import type {
  DefaultError,
  PlaceholderDataFunction,
  QueryKey,
} from '@tanstack/vue-query'
import { useQuery } from '@tanstack/vue-query'
import type {
  ComputedRef,
  MaybeRef,
  MaybeRefOrGetter,
} from 'vue'
import { computed, nextTick } from 'vue'

import { useToastMessages } from '@/composables/toastMessages.composable'
import type { PaginatedItem } from '@/models/axios/axiosPromisePaginated.type'

export interface QueryPaginatedResult<T> {
  isLoading: ComputedRef<boolean>
  isLoadingInitial: ComputedRef<boolean>
  data: ComputedRef<PaginatedItem<T>>
  refetch: () => Promise<void>
  onLoaded: (callback: (data: PaginatedItem<T>) => void) => void
}

export interface QueryResult<T> {
  isLoading: ComputedRef<boolean>
  isLoadingInitial: ComputedRef<boolean>
  data: ComputedRef<T>
  refetch: () => Promise<void>
  onLoaded: (callback: (data: T) => void) => void
}

export const EMPTY_PAGINATED_ITEM: PaginatedItem<never> = {
  items: [],
  total: 0,
}

export const DEFAULT_STALE_TIME = 1000 * 60 * 8 // 8 minutes

export interface QueryOnLoaded<T> {
  triggerCallback: (data: T) => void
  onLoaded: (callback: (data: T) => void) => void
}

export function useQueryOnLoaded<T>(): QueryOnLoaded<T> {
  let callbackFunction: ((data: T) => void) | null

  function onLoaded(callback: (data: T) => void): void {
    callbackFunction = callback
  }

  function triggerCallback(data: T): void {
    nextTick(() => {
      if (callbackFunction) {
        callbackFunction(data)
      }
    }).then()
  }

  return {
    triggerCallback,
    onLoaded,
  }
}

// eslint-disable-next-line ts/no-unsafe-function-type
type NonFunctionGuard<T> = T extends Function ? never : T
type PlaceHolderData<
  TQueryData,
  TError = DefaultError,
  TQueryKey extends QueryKey = QueryKey,
> = PlaceholderDataFunction<NonFunctionGuard<TQueryData>, TError, NonFunctionGuard<TQueryData>, TQueryKey>

export function useQueryResult<T>(options: {
  staleTime?: number
  enabled?: MaybeRefOrGetter<boolean | undefined>
  keepPreviousData?: boolean
  placeholderData?: PlaceHolderData<T>
  queryFn: () => Promise<T> | null
  queryKey: MaybeRef<QueryKey>
}): QueryResult<T | null> {
  const {
    triggerCallback,
    onLoaded,
  } = useQueryOnLoaded<T | null>()
  const toast = useToastMessages()

  const query = useQuery<T | null>({
    staleTime: options.staleTime,
    enabled: options.enabled,
    placeholderData: (data) => {
      if (options.keepPreviousData !== undefined && options.placeholderData !== undefined) {
        throw new Error('Cannot use both keepPreviousData and placeholderData')
      }

      if (options.keepPreviousData === true) {
        return data
      }

      if (options.placeholderData !== undefined) {
        return options.placeholderData(data ?? undefined, undefined)
      }

      return undefined
    },
    queryFn: async () => {
      try {
        const response = await options.queryFn()

        triggerCallback(response)

        return response
      }
      catch (error) {
        toast.pushApiError(error)

        return null
      }
    },
    queryKey: options.queryKey,
  })

  return {
    isLoading: computed<boolean>(() => query.isFetching.value),
    isLoadingInitial: computed<boolean>(() => query.isLoading.value),
    data: computed<T | null>(() => query.data.value ?? null),
    refetch: async (): Promise<void> => {
      await query.refetch()
    },
    onLoaded,
  }
}

export function useQueryPaginatedResult<T>(options: {
  staleTime?: number
  enabled?: MaybeRefOrGetter<boolean | undefined>
  keepPreviousData?: boolean
  queryFn: () => Promise<PaginatedItem<T>>
  queryKey: MaybeRef<QueryKey>
}): QueryPaginatedResult<T> {
  const {
    triggerCallback,
    onLoaded,
  } = useQueryOnLoaded<PaginatedItem<T>>()
  const toast = useToastMessages()

  const query = useQuery<PaginatedItem<T>>({
    staleTime: options.staleTime,
    enabled: options.enabled,
    placeholderData: (data) => {
      if (options.keepPreviousData) {
        return data
      }

      return undefined
    },
    queryFn: async () => {
      try {
        const response = await options.queryFn()

        triggerCallback(response)

        return response
      }
      catch (error) {
        toast.pushApiError(error)

        return EMPTY_PAGINATED_ITEM
      }
    },
    queryKey: options.queryKey,
  })

  return {
    isLoading: computed<boolean>(() => query.isFetching.value),
    isLoadingInitial: computed<boolean>(() => query.isLoading.value),
    data: computed<PaginatedItem<T>>(() => query.data.value ?? EMPTY_PAGINATED_ITEM),
    refetch: async (): Promise<void> => {
      await query.refetch()
    },
    onLoaded,
  }
}

export function useQueryListResult<T>(options: {
  staleTime?: number
  enabled?: MaybeRefOrGetter<boolean | undefined>
  keepPreviousData?: boolean
  queryFn: () => Promise<T[]>
  queryKey: MaybeRef<QueryKey>
}): QueryResult<T[]> {
  const {
    triggerCallback,
    onLoaded,
  } = useQueryOnLoaded<T[]>()
  const toast = useToastMessages()

  const query = useQuery<T[]>({
    staleTime: options.staleTime,
    enabled: options.enabled,
    placeholderData: (data) => {
      if (options.keepPreviousData) {
        return data
      }

      return undefined
    },
    queryFn: async () => {
      try {
        const response = await options.queryFn()

        triggerCallback(response)

        return response
      }
      catch (error) {
        toast.pushApiError(error)

        return []
      }
    },
    queryKey: options.queryKey,
  })

  return {
    isLoading: computed<boolean>(() => query.isFetching.value),
    isLoadingInitial: computed<boolean>(() => query.isLoading.value),
    data: computed<T[]>(() => query.data.value ?? []),
    refetch: async (): Promise<void> => {
      await query.refetch()
    },
    onLoaded,
  }
}
