import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const customerRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.CUSTOMERS.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.CUSTOMER_READ,
        ]),
      ],
    },
    path: '/customers',
    component: () => import('@/modules/customer/views/CustomerView.vue'),
  },
  {
    name: ROUTE_NAME.CUSTOMERS.DETAIL.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.CUSTOMER_READ,

        ]),
      ],
    },
    path: '/customers/:uuid',
    component: () => import('@/modules/customer/views/detail/CustomerDetailView.vue'),
    redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.INDEX },
    children: [
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.INDEX,
        path: 'overview',
        component: () => import('@/modules/customer/views/detail/overview/CustomerDetailOverviewView.vue'),
        redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.DASHBOARD },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.DASHBOARD,
            path: 'dashboard',
            component: () => import('@/modules/customer/views/detail/overview/CustomerDetailOverviewDashboardView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.OVERVIEW.LOGBOOKS,
            meta: {
              middleware: [
                permissionMiddleware([
                  Permission.LOGBOOK_READ,
                ]),
              ],
            },
            path: 'logbook',
            component: () => import('@/modules/customer/views/detail/overview/CustomerDetailOverviewLogbooksView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.INDEX,
        meta: {
          middleware: [
            permissionMiddleware([
              Permission.PLANNING_READ,
            ]),
          ],
        },
        path: 'planning',
        component: () => import('@/modules/customer/views/detail/planning/CustomerDetailPlanningView.vue'),
        redirect: {
          name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.WEEK_PLANNING,
        },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.WEEK_PLANNING,
            path: 'week-planning',
            component: () => import('@/modules/customer/views/detail/planning/CustomerDetailPlanningWeekView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.AVAILABILITIES,
            path: 'availabilities',
            component: () =>
              import('@/modules/customer/views/detail/planning/CustomerDetailPlanningAvailabilitiesView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.EMPLOYEES,
            path: 'employees',
            component: () =>
              import('@/modules/customer/views/detail/planning/CustomerDetailPlanningRegularEmployeesView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.PLANNING.REPLACEMENTS,
            path: 'replacements',
            component: () =>
              import('@/modules/customer/views/detail/planning/CustomerDetailPlanningRequestsView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.INDEX,
        path: 'details',
        component: () => import('@/modules/customer/views/detail/info/CustomerDetailInfoView.vue'),
        redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.PERSONAL },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.WORK_STATION,
            path: 'work-station',
            component: () =>
              import('@/modules/customer/views/detail/info/work-station/CustomerDetailInfoWorkStationView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.PERSONAL,
            path: 'personal',
            component: () => import('@/modules/customer/views/detail/info/personal/CustomerDetailInfoPersonalView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.REFERRAL,
            path: 'referral',
            component: () => import('@/modules/customer/views/detail/info/referral/CustomerDetailInfoReferralView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.LEAD,
            path: 'lead',
            component: () => import('@/modules/customer/views/detail/info/lead/CustomerDetailInfoLeadView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.CANCELLATION,
            path: 'cancellation',
            component: () =>
              import('@/modules/customer/views/detail/info/cancellation/CustomerDetailInfoCancellationView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.INFO.REJECTION,
            path: 'rejection',
            component: () =>
              import('@/modules/customer/views/detail/info/rejection/CustomerDetailInfoRejectionView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.INDEX,
        path: 'contract',
        component: () => import('@/modules/customer/views/detail/contract/CustomerDetailContractView.vue'),
        redirect: { name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.SUBSCRIPTION },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.SUBSCRIPTION,
            meta: {
              middleware: [
                permissionMiddleware([
                  Permission.SUBSCRIPTION_READ,
                ], {
                  name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.TERMS_OF_SERVICE,
                }),
              ],
            },
            path: 'subscription',
            component: () =>
              import(
                '@/modules/customer/views/detail/contract/subscription/CustomerDetailContractSubscriptionView.vue'
              ),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.CONTRACT.TERMS_OF_SERVICE,
            path: 'terms-of-service',
            component: () =>
              import(
                '@/modules/customer/views/detail/contract/terms-of-service/CustomerDetailContractTermsOfServiceView.vue'
              ),
          },
        ],
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.ABSENCES.INDEX,
        path: 'absence',
        component: () => import('@/modules/customer/views/detail/absences/CustomerDetailAbsencesView.vue'),
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.REFUSED_MATCHES.INDEX,
        path: 'refused-matches',
        component: () => import('@/modules/customer/views/detail/refused-match/CustomerDetailRefusedMatchesView.vue'),
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.DOCUMENTS.INDEX,
        path: 'documents',
        component: () => import('@/modules/customer/views/detail/documents/CustomerDetailDocumentsView.vue'),
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.INDEX,
        path: 'notification',
        component: () => import('@/modules/customer/views/detail/notifications/CustomerDetailNotificationsView.vue'),
        redirect: {
          name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
        },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
            path: 'damage-claims',
            component: () =>
              import('@/modules/customer/views/detail/notifications/CustomerDetailNotificationsClaimsView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.NOTIFICATIONS.WORK_ACCIDENTS,
            path: 'work-accidents',
            component: () =>
              import('@/modules/customer/views/detail/notifications/CustomerDetailNotificationsAccidentsView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.INDEX,
        meta: {
          middleware: [
            permissionMiddleware([
              Permission.CHEQUE_READ,
            ]),
          ],
        },
        path: 'service-checks',
        component: () => import('@/modules/customer/views/detail/service-checks/CustomerDetailServiceChecksView.vue'),
        redirect: {
          name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.SHORTAGES,
        },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.SHORTAGES,
            path: 'shortages',
            component: () =>
              import(
                '@/modules/customer/views/detail/service-checks/shortages/CustomerDetailServiceChecksShortagesView.vue'
              ),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.SERVICE_CHECKS.CHECKS,
            path: 'cheques',
            component: () => import('@/views/UnderConstructionView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.INDEX,
        meta: {
          middleware: [
            permissionMiddleware([
              Permission.TODO_READ,
            ]),
          ],
        },
        path: 'to-dos',
        component: () => import('@/modules/customer/views/detail/todo/CustomerDetailTodoView.vue'),
        redirect: {
          name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.OPEN,
        },
        children: [
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.OPEN,
            path: 'open',
            component: () => import('@/modules/customer/views/detail/todo/CustomerDetailTodoOpenView.vue'),
          },
          {
            name: ROUTE_NAME.CUSTOMERS.DETAIL.TO_DOS.COMPLETED,
            path: 'completed',
            component: () => import('@/modules/customer/views/detail/todo/CustomerDetailTodoCompletedView.vue'),
          },
        ],
      },
    ],
  },
  {
    name: ROUTE_NAME.CUSTOMERS.PROSPECT.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.CUSTOMER_READ,
        ]),
      ],
    },
    path: '/customers/:uuid/prospect',
    component: () => import('@/modules/customer/views/prospect/CustomerProspectView.vue'),
  },
]
