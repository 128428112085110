<script lang="ts" setup>
import AppText from '@/components/app/text/AppText.vue'
import { firstUppercase } from '@/utils/string.util'

const props = defineProps<{
  testId?: string
  label: string
}>()
</script>

<template>
  <div :data-test-id="testId">
    <AppText
      :value="firstUppercase(props.label)"
      color="white"
    />
  </div>
</template>
