// Example: John Doe
export function formatFullName(firstName?: string | null, lastName?: string | null): string {
  if (!firstName && !lastName) {
    return '-'
  }

  if (!firstName) {
    return String(lastName)
  }

  if (!lastName) {
    return String(firstName)
  }

  return `${String(firstName)} ${String(lastName)}`
}

// Example: John Doe (12345)
export function formatFullNameWithId(firstName?: string | null, lastName?: string | null, id?: number | null): string {
  const fullName = formatFullName(firstName, lastName)

  if (!id) {
    return fullName
  }

  return `${fullName} (${id})`
}

// Example: John D.
export function formatShortName(firstName: string | null, lastName: string | null): string {
  if (!firstName && !lastName) {
    return '-'
  }

  if (!firstName && lastName) {
    return `${lastName.charAt(0)}.`
  }

  if (firstName && !lastName) {
    return firstName
  }

  return `${firstName} ${lastName?.charAt(0)}.`
}
