import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const dashboardRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.DASHBOARD,
    path: '/dashboard',
    component: () => import('@/modules/dashboard/views/DashboardView.vue'),
  },
]
