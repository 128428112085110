import type { RouteRecordRaw } from 'vue-router'

import { guestMiddleware } from '@/middleware/guest.middleware.ts'
import ROUTE_NAME from '@/router/routeName'

export const AuthenticationRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.AUTH.LOGIN,
    path: '/login',
    meta: {
      middleware: [
        guestMiddleware,
      ],
    },
    component: () => import('@/modules/authentication/views/AuthLoginView.vue'),
  },
  {
    name: ROUTE_NAME.AUTH.AD_CALLBACK,
    path: '/auth-ad',
    component: () => import('@/modules/authentication/views/AuthAdCallbackView.vue'),
  },
  {
    name: ROUTE_NAME.AUTH.LOGOUT,
    path: '/logout',
    component: () => import('@/modules/authentication/views/AuthLogoutView.vue'),
  },
  {
    name: ROUTE_NAME.AUTH.MAINTENANCE,
    path: '/maintenance',
    component: () => import('@/modules/authentication/views/AuthMaintenanceModeView.vue'),
  },
  {
    name: ROUTE_NAME.AUTH.NO_PERMISSION,
    path: '/no-permission',
    component: () => import('@/modules/authentication/views/AuthNoPermissionView.vue'),
  },
]
