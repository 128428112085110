import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const remittanceRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.REMITTANCE.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.REMITTANCE_READ,
        ]),
      ],
    },
    path: '/remittance',
    component: () => import('@/modules/remittance/views/RemittanceIndexView.vue'),
  },
  {
    name: ROUTE_NAME.REMITTANCE.DETAIL,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.REMITTANCE_READ,
        ]),
      ],
    },
    path: '/remittance/:uuid',
    component: () => import('@/modules/remittance/views/RemittanceDetailView.vue'),
  },
]
