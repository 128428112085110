import type { RouteRecordRaw } from 'vue-router'

import ROUTE_NAME from '@/router/routeName'

export const requestsRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.REQUESTS.INDEX,
    path: '/requests',
    component: () => import('@/modules/requests/views/RequestsView.vue'),
    redirect: { name: ROUTE_NAME.REQUESTS.ABSENCES.INDEX },
    children: [
      {
        name: ROUTE_NAME.REQUESTS.ABSENCES.INDEX,
        redirect: { name: ROUTE_NAME.REQUESTS.ABSENCES.PENDING },
        path: 'absences',
        component: () =>
          import('@/modules/requests/views/absences/RequestsAbsencesView.vue'),
        children: [
          {
            name: ROUTE_NAME.REQUESTS.ABSENCES.PENDING,
            path: 'pending',
            component: () =>
              import('@/modules/requests/views/absences/RequestsAbsencesPendingIndexView.vue'),
          },
          {
            name: ROUTE_NAME.REQUESTS.ABSENCES.APPROVED,
            path: 'approved',
            component: () =>
              import('@/modules/requests/views/absences/RequestsAbsencesApprovedIndexView.vue'),
          },
          {
            name: ROUTE_NAME.REQUESTS.ABSENCES.REJECTED,
            path: 'approved',
            component: () =>
              import('@/modules/requests/views/absences/RequestsAbsencesRejectedIndexView.vue'),
          },
        ],
      },
    ],
  },
]
