import { useStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed } from 'vue'

import { Language } from '@/constants/language.enum'
import dayjs from '@/plugins/dayjs.plugin'
import { i18nPlugin } from '@/plugins/i18n.plugin'

export const useLanguageStore = defineStore('LanguageStore', () => {
  const locale = useStorage<Language>('language', Language.NL)

  function setLanguage(language: Language): void {
    i18nPlugin.global.locale.value = language
    locale.value = language
    dayjs.locale(language)
  }

  function initialize(): void {
    setLanguage(locale.value)
  }

  const currentLanguage = computed<Language>(() => i18nPlugin.global.locale.value as Language)

  return {
    currentLanguage,
    initialize,
    setLanguage,
  }
})
