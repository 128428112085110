import type { ComputedRef, Ref } from 'vue'
import {
  computed,
  markRaw,
  onUnmounted,
  ref,
} from 'vue'

import { generateRandomUUID } from '@/utils/math.util'

const renderedModals = ref<RenderedModal[]>([])

export interface Constructor<P = unknown> {
  new(...args: unknown[]): { $props: P }
}

export type RawProps = Record<string, unknown>

export interface UseModalOptions<P> {
  attrs?: (object extends P ? null : undefined) | (P & RawProps)
  component: Constructor<P>
}

export interface UseModalReturnType<P> {
  isOpen: ComputedRef<boolean>
  close: () => void
  destroy: () => void
  moveToBottom: () => void
  moveToTop: () => void
  open: (attrs?: (object extends P ? null : undefined) | (P & RawProps)) => void
  setLoadingState: (value: boolean) => void
}

export interface RenderedModal extends UseModalOptions<unknown> {
  id: string
  isOpen: boolean
}

export function useModalContainer(): {
  modals: Ref<RenderedModal[]>
} {
  return { modals: renderedModals }
}

export function useDynamicModal<P>(component: Constructor<P>): UseModalReturnType<P> {
  const id = generateRandomUUID()

  const isOpen = computed<boolean>(() => {
    return renderedModals.value.some((modal) => modal.id === id && modal.isOpen)
  })

  const modal = markRaw<RenderedModal>({
    id,
    isOpen: false,
    attrs: {
      onClose: () => close(),
    },
    component,
  })

  function open(attrs?: (object extends P ? null : undefined) | (P & RawProps)): void {
    if (modal.isOpen) {
      return
    }

    modal.attrs = {
      ...modal.attrs,
      ...attrs,
    }
    renderedModals.value.push(modal)
    modal.isOpen = true
  }

  function setLoadingState(value: boolean): void {
    if (modal.attrs?.isLoading === undefined) {
      throw new Error('Modal does not have isLoading attribute')
    }

    renderedModals.value = renderedModals.value.map((modal) => {
      if (modal.attrs?.isLoading !== undefined && modal.id === id) {
        modal.attrs.isLoading = value
      }

      return modal
    })
  }

  function close(): void {
    renderedModals.value = renderedModals.value.map((modal) => {
      if (modal.id === id) {
        modal.isOpen = false
      }

      return modal
    })

    setTimeout(() => {
      destroy()
    }, 300)
  }

  function moveToTop(): void {
    renderedModals.value = renderedModals.value.filter((modal) => modal.id !== id).concat(modal)
  }

  function moveToBottom(): void {
    renderedModals.value = [
      modal,
    ].concat(renderedModals.value.filter((modal) => modal.id !== id))
  }

  function destroy(): void {
    renderedModals.value = renderedModals.value.filter((modal) => modal.id !== id)
  }

  onUnmounted(() => {
    destroy()
  })

  return {
    isOpen,
    close,
    destroy,
    moveToBottom,
    moveToTop,
    open,
    setLoadingState,
  }
}
