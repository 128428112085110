import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const anomaliesRoute: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.ANOMALIES.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.ANOMALY_READ,
        ]),
      ],
    },
    path: '/anomalies',
    component: () => import('@/modules/anomalies/views/AnomaliesView.vue'),
    redirect: { name: ROUTE_NAME.ANOMALIES.SHORTAGES.INDEX },
    children: [
      {
        name: ROUTE_NAME.ANOMALIES.EXCESS.INDEX,
        path: 'excess',
        component: () => import('@/modules/anomalies/views/AnomaliesExcessView.vue'),
      },
      {
        name: ROUTE_NAME.ANOMALIES.SHORTAGES.INDEX,
        path: 'shortages',
        component: () => import('@/modules/anomalies/views/AnomaliesShortagesView.vue'),
      },
      {
        name: ROUTE_NAME.ANOMALIES.TO_VALIDATE.INDEX,
        path: 'to-validate',
        component: () => import('@/modules/anomalies/views/AnomaliesToValidateView.vue'),
      },
    ],
  },
  {
    name: ROUTE_NAME.ANOMALIES.DETAIL.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.ANOMALY_READ,
        ]),
      ],
    },
    path: '/anomalies/:employeeUuid',
    component: () => import('@/modules/anomalies/views/detail/AnomaliesDetailView.vue'),
  },
]
