import type {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationRaw,
} from 'vue-router'

import { CURRENT_ENVIRONMENT } from '@/constants/environment.constant.ts'
import type { Permission } from '@/constants/permission.enum.ts'
import { useAuthStore } from '@/modules/authentication/stores/auth.store.ts'
import { MiddlewareUtil } from '@/plugins/router/middleware.util.ts'
import type { Middleware } from '@/plugins/router/routeMiddleware.type.ts'
import ROUTE_NAME from '@/router/routeName'

export function permissionMiddleware(
  permissions: Permission[],
  fallbackRoute?: RouteLocationRaw,
): (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => void {
  return MiddlewareUtil.createMiddleware((): Middleware => {
    const authStore = useAuthStore()

    if (CURRENT_ENVIRONMENT === 'e2e') {
      return
    }

    if (authStore.hasPermission(permissions)) {
      return
    }

    if (fallbackRoute !== undefined) {
      return fallbackRoute
    }

    return {
      name: ROUTE_NAME.AUTH.NO_PERMISSION,
    }
  })
}
