<script lang="ts" setup>
import { computed } from 'vue'
import type { RouteLocationRaw } from 'vue-router'

import AppIcon from '@/components/app/icon/AppIcon.vue'
import AppTooltip from '@/components/app/tooltip/AppTooltip.vue'
import type {
  ComponentShape,
  ComponentVariant,
} from '@/composables/variants/componentVariants.composable'
import { useComponentVariants } from '@/composables/variants/componentVariants.composable'
import type { Icon } from '@/models/icon.enum'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'
import { firstUppercase } from '@/utils/string.util'

const props = defineProps<AppButtonProps>()

const emit = defineEmits<{
  'component:click': [data: Event]
}>()

const variants = useComponentVariants()

export interface AppButtonProps {
  isDisabled?: boolean
  isLoading?: boolean
  color?: DefaultThemeColor | null
  label?: string | null
  manualContentSize?: boolean
  prefixIcon?: Icon | null
  shape?: ComponentShape
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
  suffixIcon?: Icon | null
  to?: RouteLocationRaw
  tooltip?: string
  variant?: ComponentVariant
}

const size = computed<'lg' | 'md' | 'sm' | 'xl' | 'xs'>(() => props.size ?? 'md')
const variant = computed<ComponentVariant>(() => props.variant ?? 'solid')
const color = computed<DefaultThemeColor>(() => props.color ?? 'lima')
const iconColor = computed<DefaultThemeColor | undefined>(() =>
  variant.value === 'text-outline' ? props.color ?? undefined : undefined)
const shape = computed<ComponentShape>(() => props.shape ?? 'default')
const isDisabled = computed<boolean>(() => props.isDisabled || props.isLoading)

const buttonPadding = computed<string>(() => {
  if (shape.value === 'circle') {
    return 'px-[4px]'
  }

  if (variant.value === 'text-outline') {
    return 'px-2 py-1'
  }

  if (shape.value === 'default') {
    return 'py-[6px] px-[14px]'
  }

  return 'py-2 px-2'
})

const buttonComponentStyle = computed<string>(() => {
  if (props.variant === 'text-outline') {
    return (
      `${variants.getComponentStyle(variant.value, color.value, shape.value, props.isDisabled)
      } filter font-bold grid gap-[5px]${
        buttonPadding.value}`
    )
  }

  return (
    `${variants.getComponentStyle(variant.value, color.value, shape.value, props.isDisabled)
    } filter hover:brightness-110 font-bold grid gap-[5px] ${
      buttonPadding.value}`
  )
})

const textSize = computed<string>(() => {
  const sizes: Record<string, string> = {
    lg: 'text-lg',
    md: 'text-md',
    sm: 'text-sm',
  }

  return sizes[size.value]
})

const spinnerComponentStyle = computed<string>(() =>
  variant.value === 'solid' ? '!text-white' : `!text-${props.color}`)

const buttonComponentType = computed<'button' | 'router-link'>(() => {
  if (props.to) {
    return 'router-link'
  }

  return 'button'
})

function onClick(event: Event): void {
  if (isDisabled.value) {
    return
  }

  emit('component:click', event)
}
</script>

<template>
  <component
    :is="buttonComponentType"
    :class="buttonComponentStyle"
    :disabled="isDisabled"
    :to="props.to"
    @click.stop="onClick"
  >
    <AppTooltip
      :delay-duration="300"
      :value="props.tooltip"
      as-child
    >
      <span
        :class="{ 'opacity-0': isLoading }"
        class="flex items-center justify-center gap-x-2 whitespace-nowrap transition-opacity"
      >
        <AppIcon
          v-if="prefixIcon"
          :color="iconColor"
          :icon="prefixIcon"
          :size="size"
        />
        <span
          v-if="label"
          :class="textSize"
        >
          {{ firstUppercase(label, true) }}
        </span>
        <slot />
        <AppIcon
          v-if="suffixIcon"
          :icon="suffixIcon"
          :size="size"
        />
      </span>
    </AppTooltip>

    <span
      v-if="isLoading"
      class="absolute flex size-full items-center justify-center"
      data-test-id="loading-spinner"
    >
      <a
        :class="spinnerComponentStyle"
        class="pi pi-spinner pi-spin"
      />
    </span>
  </component>
</template>
