import { ZitadelClient } from '@wisemen/vue-core-auth'

import {
  AUTH_BASE_URL,
  AUTH_CLIENT_ID,
  CURRENT_ENVIRONMENT,
} from '@/constants/environment.constant.ts'
import { httpClient } from '@/http/httpClient.ts'

export const oAuthClient = new ZitadelClient({
  clientId: AUTH_CLIENT_ID,
  organizationId: '284457840993688250',
  axios: httpClient,
  baseUrl: AUTH_BASE_URL,
  loginRedirectUri: `${window.location.origin}/auth-ad`,
  offline: CURRENT_ENVIRONMENT === 'e2e',
  postLogoutRedirectUri: `${window.location.origin}/logout`,
})
