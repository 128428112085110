import { isArraysEqual } from '@fullcalendar/core/internal'

import type {
  PaginationMatch,
  PaginationOptions,
} from '@/models/axios/paginationOptions.type'
import { isNotEmpty } from '@/utils/object.util'

export const CLEAR_IN_MERGE_VALUE = 'CLEAR_IN_MERGE_VALUE'

export function mergePaginationOptions<T extends string>(
  oldPaginationOptions: PaginationOptions<T>,
  newPaginationOptions: PaginationOptions<T>,
): PaginationOptions<T> {
  const mergedPaginationOptions = {
    ...oldPaginationOptions,
  }

  if (newPaginationOptions.match) {
    let newMatch = newPaginationOptions.match
    let oldMatch = mergedPaginationOptions.match

    const clearedKeys = Object.entries(newMatch)
      .map(([
        key,
        value,
      ]) => {
        if (Array.isArray(value) && isArraysEqual(value, [
          CLEAR_IN_MERGE_VALUE,
        ])) {
          return key
        }

        return null
      })
      .filter(isNotEmpty)

    if (clearedKeys.length > 0) {
      oldMatch = {
        ...oldMatch,
        ...clearedKeys.reduce((acc, cur) => ({
          ...acc,
          [cur]: undefined,
        }), {}),
      } as PaginationMatch<T>

      newMatch = {
        ...newMatch,
        ...clearedKeys.reduce((acc, cur) => ({
          ...acc,
          [cur]: undefined,
        }), {}),
      }
    }

    mergedPaginationOptions.match = {
      ...oldMatch,
      ...newMatch,
    }
  }

  if (newPaginationOptions.like) {
    mergedPaginationOptions.like = {
      ...mergedPaginationOptions.like,
      ...newPaginationOptions.like,
    }
  }

  if (newPaginationOptions.sort) {
    mergedPaginationOptions.sort = {
      ...mergedPaginationOptions.sort,
      ...newPaginationOptions.sort,
    }
  }

  return mergedPaginationOptions
}
