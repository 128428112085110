export function firstUppercase(value: string | null | undefined, toLowerCase = false): string {
  if (!value) {
    return ''
  }

  if (toLowerCase) {
    return value?.toString()?.charAt(0)?.toUpperCase() + value?.toString()?.slice(1)?.toLowerCase()
  }

  return value?.toString()?.charAt(0)?.toUpperCase() + value?.toString()?.slice(1)
}

export class StringUtil {
  static replaceAll(value: string, search: string, replace: string): string {
    return value.split(search).join(replace)
  }

  static upperCaseOnlyFirst(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
  }
}

export function isEmptyString(value: string | null | undefined): boolean {
  if (!value) {
    return true
  }

  return value === ''
}

export function mapStringToNullable(value: string | null | undefined): string | null {
  return value || null
}

export function convertPascalCaseToSnakeCase(str: string | null): string | null {
  if (!str) {
    return null
  }

  return str.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()
}

export function stringOnlyContainsNumbers(str: string): boolean {
  return /^\d+$/.test(str)
}

function getCalculatedNumber(pluxeeClientNumber: string): string {
  const result = String(Number(pluxeeClientNumber) % 97).padStart(2, '0')

  // special case when modulo is 0
  if (result === '00') {
    return '97'
  }

  return result
}

export function parsePluxeeClientNumber(pluxeeClientNumber: string | null): string | null {
  if (!pluxeeClientNumber) {
    return null
  }

  if (pluxeeClientNumber.length !== 10) {
    return pluxeeClientNumber
  }

  const calculatedNumber = getCalculatedNumber(pluxeeClientNumber)

  return pluxeeClientNumber + calculatedNumber
}

export function roundNumberToTwoDecimals(value: number): number {
  return Math.round((value + Number.EPSILON) * 100) / 100
}

export function roundNumberToOneDecimal(value: number): number {
  return Math.round((value + Number.EPSILON) * 10) / 10
}
