import type { ComputedRef } from 'vue'
import { computed } from 'vue'

import { CURRENT_ENVIRONMENT } from '@/constants/environment.constant'

export enum EnvironmentMode {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  QA = 'qa',
  SANDBOX = 'sandbox',
  STAGING = 'staging',
}

interface Environment {
  isAllowed: (environmentMode: EnvironmentMode[]) => boolean
  isDevelopment: ComputedRef<boolean>
  currentEnvironment: ComputedRef<EnvironmentMode>
  version: ComputedRef<string>
}

export function useEnvironment(): Environment {
  const isDevelopment = computed<boolean>(() => {
    return CURRENT_ENVIRONMENT === EnvironmentMode.DEVELOPMENT
  })

  const currentEnvironment = computed<EnvironmentMode>(() => {
    return CURRENT_ENVIRONMENT as EnvironmentMode
  })

  const version = computed<string>(() => {
    return BUILD_NUMBER as string
  })

  function isAllowed(environmentMode: EnvironmentMode[]): boolean {
    if (CURRENT_ENVIRONMENT === undefined) {
      return true
    }

    return environmentMode.includes(CURRENT_ENVIRONMENT as EnvironmentMode)
  }

  return {
    isAllowed,
    isDevelopment,
    currentEnvironment,
    version,
  }
}
