import Toast, { POSITION } from 'vue-toastification'

export const toastPluginConfig = {
  closeOnClick: false,
  maxToasts: 5,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  position: POSITION.BOTTOM_RIGHT,
  showCloseButtonOnHover: true,
  timeout: 5000,
  transition: 'Vue-Toastification__fade',
}

export const toastPlugin = Toast
