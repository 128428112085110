import type { IntlDateTimeFormats } from 'vue-i18n'
import { createI18n } from 'vue-i18n'

import fr from '@/locales/fr.json' assert { type: 'json' }
import nl from '@/locales/nl.json' assert { type: 'json' }

const DEFAULT_LOCALE = 'nl'
const FALLBACK_DEFAULT_LOCALE = 'fr'

export const dateTimeFormats: IntlDateTimeFormats = {
  fr: {
    long: {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      weekday: 'long',
      year: 'numeric',
    },
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
  },
  nl: {
    long: {
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      month: 'long',
      weekday: 'long',
      year: 'numeric',
    },
    short: {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
  },
}

type Flatten<T, P extends string = ''> = {
  [K in keyof T]: T[K] extends object ? `${P}${K & string}` | Flatten<T[K], `${P}${K & string}.`> : `${P}${K & string}`
}[keyof T]

export type I18nKey = Flatten<typeof nl>

export const i18nPlugin = createI18n({
  datetimeFormats: dateTimeFormats,
  defaultLocale: DEFAULT_LOCALE,
  fallbackLocale: FALLBACK_DEFAULT_LOCALE,
  legacy: false,
  locale: DEFAULT_LOCALE,
  messages: {
    fr,
    nl,
  },
})
