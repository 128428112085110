import { computed } from 'vue'

import type { DataItem } from '@/models/dataItem.type'
import { i18nPlugin } from '@/plugins/i18n.plugin'

export enum ApiError {
  ABSENCE_EXCLUSIVE_FOR_AT_LEAST_HALF_TIME = 'absence_exclusive_for_at_least_half_time',
  ABSENCE_EXCLUSIVE_FOR_IN_NOTICE = 'absence_exclusive_for_in_notice',
  ABSENCE_EXCLUSIVE_FOR_UNION_REPRESENTATIVE = 'absence_exclusive_for_union_representative',
  ABSENCE_NOT_WITHIN_ONE_TIMETABLE = 'absence_not_within_one_timetable',
  ABSENCE_OVERLAPS = 'absence_overlaps',
  ADDRESS_HISTORY_FIRST = 'address_history_first',
  ADVANCE_PAYMENT_NOT_WANTED = 'advance_payment_not_wanted',
  ALARIS_CONNECTION_CLOSED = 'alaris_connection_closed',
  ALARIS_CONNECTION_REFUSED = 'alaris_connection_refused',
  ALARIS_CONNECTION_TIMEOUT = 'alaris_connection_timeout',
  ALARIS_ERROR = 'alaris_error',
  ALARIS_ERROR_NO_PAPER = 'alaris_error_no_paper',
  ALARIS_SCANNER_LOCKED = 'alaris_scanner_locked',
  ALARIS_SCANNER_WAKING_UP = 'alaris_scanner_waking_up',
  ALREADY_CHECKED = 'already_checked',
  ALREADY_EXISTS = 'already_exists',
  ALREADY_UNCHECKED = 'already_unchecked',
  AMOUNT_WITH_COMPANY_CAR_REQUIRED = 'amount_with_company_car_required',
  AVAILABLE_HOURS_LOWER_THAN_PREFERRED_PART_TIME_HOURS = 'available_hours_lower_than_preferred_part_time_hours',
  AZURE_API_ERROR = 'azure_api_error',

  CALCULATION_DATE_REQUIRED = 'calculation_date_required',
  CHEQUE_DELETE_REJECTED = 'cheque_delete_rejected',
  CHEQUE_UPDATE_BARCODE_REJECTED = 'cheque_update_barcode_rejected',
  CHEQUE_UPDATE_DUPLICATE_BARCODE = 'cheque_update_duplicate_barcode',
  CHEQUE_UPDATE_REJECTED = 'cheque_update_rejected',
  CONFIRMED_PAYMENTS_DEPARTMENT = 'confirmed_payments_department',
  CONTACT_METHOD_REQUIRED = 'contact_method_required',
  CONTRACT_ALREADY_STARTED = 'contract_already_started',
  CONTRACT_CONCEPT_ALREADY_EXISTS = 'contract_concept_already_exists',
  CONTRACT_CONCEPT_OVERLAPS = 'contract_concept_overlaps',
  CONTRACT_IS_NOT_CONCEPT = 'contract_is_not_concept',
  CONTRACT_IS_NOT_SIGNED = 'contract_is_not_signed',
  CONTRACT_MISSING_DATA = 'contract_missing_data',
  CONTRACT_RESIDENCE_PERMIT_ENDED = 'contract_residence_permit_ended',

  CONTRACT_WORK_PERMIT_ENDED = 'contract_work_permit_ended',
  COORDINATES_NOT_PRESENT = 'coordinates_not_present',
  COUNTER_AMOUNT_EXCEEDED = 'counter_amount_exceeded',
  COUNTER_AMOUNT_TOO_HIGH = 'counter_amount_too_high',
  COUNTER_NOT_PRESENT = 'counter_not_present',
  CUSTOMER_ABSENCE_DATE_IN_PAST = 'customer_absence_date_in_past',
  CUSTOMER_ABSENCE_SPLITS_PLANNING_EVENT = 'customer_absence_splits_planning_event',
  CUSTOMER_DOES_NOT_OWN_PAYMENT_IDENTITY = 'customer_does_not_own_payment_identity',
  CUSTOMER_NEEDS_TERMS_OF_SERVICE_ACCEPTANCE = 'customer_needs_terms_of_service_acceptance',
  CUSTOMER_NEEDS_WORK_STATION_SHEET_ACCEPTANCE = 'customer_needs_work_station_sheet_acceptance',

  CUSTOMER_TERMS_OF_SERVICE_MAIL_MISSING = 'customer_terms_of_service_mail_missing',
  CUSTOMER_WORK_STATION_SHEET_MAIL_MISSING = 'customer_work_station_sheet_mail_missing',
  DATE_AFTER_FIRST_CONTRACT_START_DATE = 'date_after_first_contract_start_date',
  DEACTIVATION_DATE_AFTER_END = 'deactivation_date_after_end',
  DEBTOR_CASE_CLOSED = 'debtor_case_closed',
  DEBTOR_CASE_EMPTY = 'debtor_case_empty',
  DEBTOR_CASE_EXISTS = 'debtor_case_exists',
  DEBTOR_CASE_INVALID_TRANSITION = 'debtor_case_invalid_transition',
  DEBTOR_CASE_LIMIT = 'debtor_case_limit',
  DEPARTMENT_ACCESS_FORBIDDEN = 'department_access_forbidden',
  DEPARTMENT_ALREADY_MERGED = 'department_already_merged',
  DEPARTMENT_CONTAINS_ACTIVE_CONTRACTS = 'department_contains_active_contracts',
  DEPARTMENT_CONTAINS_STAFF = 'department_contains_staff',
  DEPARTMENT_MERGE_BRANCH_PAYROLLS_DO_NOT_MATCH = 'department_merge_branch_payrolls_do_not_match',
  DEPARTMENT_MERGE_DATE_IN_PAST = 'department_merge_date_in_past',
  DEPARTMENT_MERGE_SODEXO_SUBSIDIARIES_DO_NOT_MATCH = 'department_merge_sodexo_subsidiaries_do_not_match',
  DEPARTMENT_MERGE_SOURCE_AND_TARGET_MATCH = 'department_merge_source_and_target_match',

  DIFFERENT_DIMONA_PERIOD_NUMBER_EXISTS_FOR_CONTRACT = 'different_dimona_period_number_exists_for_contract',
  DIMONA_DECLARATION_NUMBER_ALREADY_EXISTS = 'dimona_declaration_number_already_exists',
  DIMONA_ERROR = 'dimona_error',
  DIMONA_SERVER_ERROR = 'dimona_server_error',
  DOMICILE_NOT_FRANCE_FRENCH_BORDER_WORKER = 'domicile_not_france_french_border_worker',
  DUPLICATE_EMPLOYEE = 'duplicate_employee',
  ECONOMIC_UNEMPLOYMENT_DECLARATION_CANNOT_BE_REUPLOADED = 'economic_unemployment_declaration_cannot_be_reuploaded',
  ECONOMIC_UNEMPLOYMENT_PERIOD_ALREADY_STARTED = 'economic_unemployment_period_already_started',
  ECONOMIC_UNEMPLOYMENT_PERIOD_CANNOT_END_LATER = 'economic_unemployment_period_cannot_end_later',
  ECONOMIC_UNEMPLOYMENT_PERIOD_NOT_ACCEPTED_BY_RSZ = 'economic_unemployment_period_not_accepted_by_rsz',
  ECONOMIC_UNEMPLOYMENT_PERIOD_NOT_ACTIVE_FOR_DATE_RANGE = 'economic_unemployment_period_not_active_for_date_range',
  ECONOMIC_UNEMPLOYMENT_PERIOD_PENDING_OR_PROCESSING = 'economic_unemployment_period_pending_or_processing',
  ECONOMIC_UNEMPLOYMENT_PLANNED_FULL_WEEK = 'economic_unemployment_planned_full_week',
  ECONOMIC_UNEMPLOYMENT_PLANNED_IN_PAST = 'economic_unemployment_planned_in_past',
  ECONOMIC_UNEMPLOYMENT_PLANNED_PART_TIME = 'economic_unemployment_planned_part_time',
  EMAIL_OR_PHONE_NUMBER_REQUIRED = 'email_or_phone_number_required',
  EMPLOYEE_BENEFIT_CODE_NOT_ASSIGNABLE = 'employee_benefit_code_not_assignable',
  EMPLOYEE_DEPARTMENT_HAS_NO_BRANCH = 'employee_department_has_no_branch',
  EMPLOYEE_DEPARTMENT_HAS_ONE_IN_FUTURE = 'employee_department_has_one_in_future',
  EMPLOYEE_DEPARTMENT_IN_PAST = 'employee_department_in_past',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_CANNOT_BE_REUPLOADED = 'employee_economic_unemployment_cannot_be_reuploaded',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_HAS_OLDER_ABSENCES = 'employee_economic_unemployment_has_older_absences',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_HAS_UNFINISHED_JOBS = 'employee_economic_unemployment_has_unfinished_jobs',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_MULTIPLE_DAYS = 'employee_economic_unemployment_multiple_days',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_NUMBER_IN_USE = 'employee_economic_unemployment_number_in_use',

  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_NUMBER_NOT_FOUND = 'employee_economic_unemployment_number_not_found',

  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_NUMBER_WRONG_FORMAT = 'employee_economic_unemployment_number_wrong_format',

  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_PASSED_CANCEL_DATE = 'employee_economic_unemployment_passed_cancel_date',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_UPLOADED = 'employee_economic_unemployment_uploaded',

  EMPLOYEE_HAS_NO_CONTRACT_FOR_WAGE = 'employee_has_no_contract_for_wage',
  END_BEFORE_START = 'end_before_start',
  FUTURE_TIMETABLE_ALREADY_EXISTS = 'future_timetable_already_exists',
  GENERAL_CONDITION_EMAIL_REQUIRED = 'general_condition_email_required',
  HOLIDAY_EXISTS_ON_DATE = 'holiday_exists_on_date',
  HOLIDAY_IN_PAST = 'holiday_in_past',
  ILLEGAL_OR_BANK_HOLIDAY_FOLLOW_UP = 'illegal_or_bank_holiday_follow_up',
  ILLNESS_INVALID_EXTENSION = 'illness_invalid_extension',
  ILLNESS_INVALID_RELAPSE = 'illness_invalid_relapse',
  ILLNESS_OVERLAPPING = 'illness_overlapping',
  IN_PAST = 'in_past',
  INVALID_AVAILABILITY = 'invalid_availability',
  INVALID_CSV_ROW = 'invalid_csv_row',
  CSV_FIELD_PARSE_ERROR = 'csv_field_parse_error',
  CSV_MISSING_COLUMN = 'csv_missing_column',

  INVALID_DEPARTMENT = 'invalid_department',
  INVALID_EMPLOYEE_TRANSITION = 'invalid_employee_transition',
  INVALID_ENUM = 'invalid_enum',
  INVALID_ID = 'invalid_id',

  INVALID_PERIODICITY = 'invalid_periodicity',
  INVALID_SCAN_BARCODE = 'invalid_scan_barcode',
  INVALID_SEARCH_FIELD = 'invalid_search_field',

  MANDATORY_WORK_WEEK_PRESENT_IN_DATE_RANGE = 'mandatory_work_week_present_in_date_range',
  MAX_TEMPORARY_CONTRACTS_EXCEEDED = 'max_temporary_contracts_exceeded',

  MAXIMUM_TEMPORARY_WORK_PERIOD_EXCEEDED = 'maximum_temporary_work_period_exceeded',
  MISSING_PERMISSIONS = 'missing_permissions',
  NEXT_EXISTS = 'next_exists',
  NO_EMAIL_GIVEN_FOR_DELIVERY_METHOD_EMAIL = 'no_email_given_for_delivery_method_email',
  NOT_ALL_PAYROLL_OFFICES_MATCH = 'not_all_payroll_offices_match',
  NOT_EDITABLE = 'not_editable',
  NOT_WORKING_DURING_ACCIDENT = 'not_working_during_accident',

  OFFICE_HAS_ACTIVE_EMPLOYEES = 'office_has_active_employees',
  OVERLAPPING_AVAILABILITY = 'overlapping_availability',
  PART_TIME_ABSENCES_EXIST_IN_TIMETABLE = 'part_time_absences_exist_in_timetable',

  PART_TIME_HOURS_REQUIRED = 'part_time_hours_required',
  PARTENA_ABSENCE_CODE_ALREADY_USED = 'partena_absence_code_already_used',
  PAYROLL_ALREADY_MERGED = 'payroll_already_merged',
  PAYROLL_MERGE_SOURCE_AND_TARGET_MATCH = 'payroll_merge_source_and_target_match',

  PERFORMANCE_CHEQUES_COLLECTED_TOO_HIGH = 'performance_checks_collected_too_high',
  PERFORMANCE_COLLECT_ONLY_PAPER = 'performance_collect_only_paper',
  PERFORMANCE_COLLECTED_MORE_THAN_REQUIRED = 'performance_collected_more_than_required',

  PERFORMANCE_IS_REGISTERED = 'performance_is_registered',

  PERFORMANCE_MANUAL_FUNDED_HOURS_TOO_HIGH = 'performance_manual_funded_hours_too_high',

  PERFORMANCE_STATUS_SET = 'performance_status_set',

  RECOMATICS_ERROR = 'recomatics_error',

  REMARKS_REQUIRED = 'remarks_required',
  REQUEST_LIMIT_EXCEEDED = 'request_limit_exceeded',

  SALARY_ALREADY_CONFIRMED = 'salary_already_confirmed',
  SALARY_CALCULATING = 'salary_calculating',

  SALARY_GLOBAL_ALREADY_CONFIRMED = 'salary_global_already_confirmed',

  SALARY_HAS_NEGATIVE_COUNTER = 'salary_has_negative_counter',
  SUBSCRIPTION_NOT_ACTIVE = 'subscription_not_active',
  TAX_WITHHOLDING_OVERLAPS = 'tax_withholding_overlaps',
  TIMETABLE_NOT_EDITABLE = 'timetable_not_editable',
  TIMETABLES_STARTED_ON_OR_AFTER_MERGE_EXIST = 'timetables_started_on_or_after_merge_exist',
  TYPESENSE_UNINITIALIZED = 'typesense_uninitialized',
  UNCONFIRMED_SALARIES = 'unconfirmed_salaries',
  UNKNOWN_MERGE_ERROR = 'unknown_merge_error',
  UPDATE_ECONOMIC_UNEMPLOYMENT_ABSENCE_NOT_ALLOWED = 'update_economic_unemployment_absence_not_allowed',
  VALIDATION_ERROR = 'validation_error',

  WAGE_GARNISHMENT_NOT_FOUND = 'wage_garnishment_not_found',
}

export const API_ERROR_OPTIONS = computed<DataItem<ApiError>[]>(() => {
  const { t } = i18nPlugin.global

  return [
    {
      label: t('validations.absence.illness_invalid_extension'),
      value: ApiError.ILLNESS_INVALID_EXTENSION,
    },
    {
      label: t('validations.absence.illness_invalid_relapse'),
      value: ApiError.ILLNESS_INVALID_RELAPSE,
    },
    {
      label: t('validations.api.not_editable'),
      value: ApiError.NOT_EDITABLE,
    },
    {
      label: t('validations.absence.illness_overlapping_existing'),
      value: ApiError.ILLNESS_OVERLAPPING,
    },
    {
      label: t('validations.api.date_after_first_contract_start_date'),
      value: ApiError.DATE_AFTER_FIRST_CONTRACT_START_DATE,
    },
    {
      label: t('validations.api.missing_permissions'),
      value: ApiError.MISSING_PERMISSIONS,
    },
    {
      label: t('validations.api.holiday_in_past'),
      value: ApiError.HOLIDAY_IN_PAST,
    },
    {
      label: t('validations.api.typesense_uninitialized'),
      value: ApiError.TYPESENSE_UNINITIALIZED,
    },
    {
      label: t('validations.api.in_past'),
      value: ApiError.IN_PAST,
    },
    {
      label: t('validations.api.next_exists'),
      value: ApiError.NEXT_EXISTS,
    },
    {
      label: t('validations.api.already_exists'),
      value: ApiError.ALREADY_EXISTS,
    },
    {
      label: t('validations.api.already_checked'),
      value: ApiError.ALREADY_CHECKED,
    },
    {
      label: t('validations.api.already_unchecked'),
      value: ApiError.ALREADY_UNCHECKED,
    },
    {
      label: t('validations.api.invalid_availability'),
      value: ApiError.INVALID_AVAILABILITY,
    },
    {
      label: t('validations.api.overlapping_availability'),
      value: ApiError.OVERLAPPING_AVAILABILITY,
    },
    {
      label: t('validations.api.duplicate_employee'),
      value: ApiError.DUPLICATE_EMPLOYEE,
    },
    {
      label: t('validations.api.invalid_employee_transition'),
      value: ApiError.INVALID_EMPLOYEE_TRANSITION,
    },
    {
      label: t('validations.api.invalid_department'),
      value: ApiError.INVALID_DEPARTMENT,
    },
    {
      label: t('validations.api.invalid_search_field'),
      value: ApiError.INVALID_SEARCH_FIELD,
    },
    {
      label: t('validations.api.end_before_start'),
      value: ApiError.END_BEFORE_START,
    },
    {
      label: t('validations.api.absence_not_within_one_timetable'),
      value: ApiError.ABSENCE_NOT_WITHIN_ONE_TIMETABLE,
    },
    {
      label: t('validations.api.email_or_phone_number_required'),
      value: ApiError.EMAIL_OR_PHONE_NUMBER_REQUIRED,
    },
    {
      label: t('validations.api.part_time_hours_required'),
      value: ApiError.PART_TIME_HOURS_REQUIRED,
    },
    {
      label: t('validations.api.contact_method_required'),
      value: ApiError.CONTACT_METHOD_REQUIRED,
    },
    {
      label: t('validations.api.remarks_required'),
      value: ApiError.REMARKS_REQUIRED,
    },
    {
      label: t('validations.api.general_condition_email_required'),
      value: ApiError.GENERAL_CONDITION_EMAIL_REQUIRED,
    },
    {
      label: t('validations.api.subscription_not_active'),
      value: ApiError.SUBSCRIPTION_NOT_ACTIVE,
    },
    {
      label: t('validations.api.amount_with_company_car_required'),
      value: ApiError.AMOUNT_WITH_COMPANY_CAR_REQUIRED,
    },
    {
      label: t('validations.api.calculation_date_required'),
      value: ApiError.CALCULATION_DATE_REQUIRED,
    },
    {
      label: t('validations.api.invalid_periodicity'),
      value: ApiError.INVALID_PERIODICITY,
    },
    {
      label: t('validations.api.deactivation_date_after_end'),
      value: ApiError.DEACTIVATION_DATE_AFTER_END,
    },
    {
      label: t('validations.api.department_contains_staff'),
      value: ApiError.DEPARTMENT_CONTAINS_STAFF,
    },
    {
      label: t('validations.api.debtor_case_limit'),
      value: ApiError.DEBTOR_CASE_LIMIT,
    },
    {
      label: t('validations.api.debtor_case_empty'),
      value: ApiError.DEBTOR_CASE_EMPTY,
    },
    {
      label: t('validations.api.debtor_case_invalid_transition'),
      value: ApiError.DEBTOR_CASE_INVALID_TRANSITION,
    },
    {
      label: t('validations.api.debtor_case_exists'),
      value: ApiError.DEBTOR_CASE_EXISTS,
    },
    {
      label: t('validations.api.debtor_case_closed'),
      value: ApiError.DEBTOR_CASE_CLOSED,
    },
    {
      label: t('validations.api.department_access_forbidden'),
      value: ApiError.DEPARTMENT_ACCESS_FORBIDDEN,
    },
    {
      label: t('validations.api.performance_collect_only_paper'),
      value: ApiError.PERFORMANCE_COLLECT_ONLY_PAPER,
    },
    {
      label: t('validations.api.performance_collected_more_than_required'),
      value: ApiError.PERFORMANCE_COLLECTED_MORE_THAN_REQUIRED,
    },
    {
      label: t('validations.api.invalid_id'),
      value: ApiError.INVALID_ID,
    },
    {
      label: t('validations.api.contract_is_not_concept'),
      value: ApiError.CONTRACT_IS_NOT_CONCEPT,
    },
    {
      label: t('validations.api.dimona_error'),
      value: ApiError.DIMONA_ERROR,
    },
    {
      label: t('validations.api.dimona_server_error'),
      value: ApiError.DIMONA_SERVER_ERROR,
    },
    {
      label: t('validations.api.dimona_declaration_number_already_exists'),
      value: ApiError.DIMONA_DECLARATION_NUMBER_ALREADY_EXISTS,
    },
    {
      label: t('validations.api.contract_missing_data'),
      value: ApiError.CONTRACT_MISSING_DATA,
    },
    {
      label: t('validations.api.contract_is_not_signed'),
      value: ApiError.CONTRACT_IS_NOT_SIGNED,
    },
    {
      label: t('validations.api.contract_already_started'),
      value: ApiError.CONTRACT_ALREADY_STARTED,
    },
    {
      label: t('validations.api.contract_work_permit_ended'),
      value: ApiError.CONTRACT_WORK_PERMIT_ENDED,
    },
    {
      label: t('validations.api.contract_residence_permit_ended'),
      value: ApiError.CONTRACT_RESIDENCE_PERMIT_ENDED,
    },
    {
      label: t('validations.api.timetable_not_editable'),
      value: ApiError.TIMETABLE_NOT_EDITABLE,
    },
    {
      label: t('validations.api.performance_status_set'),
      value: ApiError.PERFORMANCE_STATUS_SET,
    },
    {
      label: t('validations.api.performance_checks_collected_too_high'),
      value: ApiError.PERFORMANCE_CHEQUES_COLLECTED_TOO_HIGH,
    },
    {
      label: t('validations.api.salary_already_confirmed'),
      value: ApiError.SALARY_ALREADY_CONFIRMED,
    },
    {
      label: t('validations.api.invalid_enum'),
      value: ApiError.INVALID_ENUM,
    },
    {
      label: t('validations.api.azure_api_error'),
      value: ApiError.AZURE_API_ERROR,
    },
    {
      label: t('validations.api.contract_concept_overlaps'),
      value: ApiError.CONTRACT_CONCEPT_OVERLAPS,
    },
    {
      label: t('validations.api.wage_garnishment_not_found'),
      value: ApiError.WAGE_GARNISHMENT_NOT_FOUND,
    },
    {
      label: t('validations.api.alaris_error'),
      value: ApiError.ALARIS_ERROR,
    },
    {
      label: t('validations.api.alaris_error_no_paper'),
      value: ApiError.ALARIS_ERROR_NO_PAPER,
    },
    {
      label: t('validations.api.alaris_connection_refused'),
      value: ApiError.ALARIS_CONNECTION_REFUSED,
    },
    {
      label: t('validations.api.alaris_connection_timeout'),
      value: ApiError.ALARIS_CONNECTION_TIMEOUT,
    },
    {
      label: t('validations.api.alaris_connection_closed'),
      value: ApiError.ALARIS_CONNECTION_CLOSED,
    },
    {
      label: t('validations.api.alaris_scanner_waking_up'),
      value: ApiError.ALARIS_SCANNER_WAKING_UP,
    },
    {
      label: t('validations.api.alaris_scanner_locked'),
      value: ApiError.ALARIS_SCANNER_LOCKED,
    },
    {
      label: t('validations.api.recomatics_error'),
      value: ApiError.RECOMATICS_ERROR,
    },
    {
      label: t('validations.api.future_timetable_already_exists'),
      value: ApiError.FUTURE_TIMETABLE_ALREADY_EXISTS,
    },
    {
      label: t('validations.api.employee_benefit_code_not_assignable'),
      value: ApiError.EMPLOYEE_BENEFIT_CODE_NOT_ASSIGNABLE,
    },
    {
      label: t('validations.api.request_limit_exceeded'),
      value: ApiError.REQUEST_LIMIT_EXCEEDED,
    },
    {
      label: t('validations.api.cheque_delete_rejected'),
      value: ApiError.CHEQUE_DELETE_REJECTED,
    },
    {
      label: t('validations.api.cheque_update_rejected'),
      value: ApiError.CHEQUE_UPDATE_REJECTED,
    },
    {
      label: t('validations.api.cheque_update_barcode_rejected'),
      value: ApiError.CHEQUE_UPDATE_BARCODE_REJECTED,
    },
    {
      label: t('validations.api.cheque_update_duplicate_barcode'),
      value: ApiError.CHEQUE_UPDATE_DUPLICATE_BARCODE,
    },
    {
      label: t('validations.api.customer_absence_date_in_past'),
      value: ApiError.CUSTOMER_ABSENCE_DATE_IN_PAST,
    },
    {
      label: t('validations.api.customer_absence_splits_planning_event'),
      value: ApiError.CUSTOMER_ABSENCE_SPLITS_PLANNING_EVENT,
    },
    {
      label: t('validations.api.customer_terms_of_service_mail_missing'),
      value: ApiError.CUSTOMER_TERMS_OF_SERVICE_MAIL_MISSING,
    },
    {
      label: t('validations.api.customer_needs_work_station_sheet_acceptance'),
      value: ApiError.CUSTOMER_NEEDS_WORK_STATION_SHEET_ACCEPTANCE,
    },
    {
      label: t('validations.api.customer_work_station_sheet_mail_missing'),
      value: ApiError.CUSTOMER_WORK_STATION_SHEET_MAIL_MISSING,
    },
    {
      label: t('validations.api.invalid_scan_barcode'),
      value: ApiError.INVALID_SCAN_BARCODE,
    },
    {
      label: t('validations.api.employee_department_has_no_branch'),
      value: ApiError.EMPLOYEE_DEPARTMENT_HAS_NO_BRANCH,
    },
    {
      label: t('validations.api.not_working_during_accident'),
      value: ApiError.NOT_WORKING_DURING_ACCIDENT,
    },
    {
      label: t('validations.api.employee_economic_unemployment_number_not_found'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_NUMBER_NOT_FOUND,
    },
    {
      label: t('validations.api.economic_unemployment_period_not_accepted_by_rsz'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PERIOD_NOT_ACCEPTED_BY_RSZ,
    },
    {
      label: t('validations.api.economic_unemployment_period_pending_or_processing'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PERIOD_PENDING_OR_PROCESSING,
    },
    {
      label: t('validations.api.economic_unemployment_period_not_active_for_date_range'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PERIOD_NOT_ACTIVE_FOR_DATE_RANGE,
    },
    {
      label: t('validations.api.economic_unemployment_planned_part_time'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PLANNED_PART_TIME,
    },
    {
      label: t('validations.api.economic_unemployment_declaration_cannot_be_reuploaded'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_DECLARATION_CANNOT_BE_REUPLOADED,
    },
    {
      label: t('validations.api.economic_unemployment_period_cannot_end_later'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PERIOD_CANNOT_END_LATER,
    },
    {
      label: t('validations.api.economic_unemployment_planned_full_week'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PLANNED_FULL_WEEK,
    },
    {
      label: t('validations.api.economic_unemployment_planned_in_past'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PLANNED_IN_PAST,
    },
    {
      label: t('validations.api.mandatory_work_week_present_in_date_range'),
      value: ApiError.MANDATORY_WORK_WEEK_PRESENT_IN_DATE_RANGE,
    },
    {
      label: t('validations.api.economic_unemployment_period_already_started'),
      value: ApiError.ECONOMIC_UNEMPLOYMENT_PERIOD_ALREADY_STARTED,
    },
    {
      label: t('validations.api.employee_economic_unemployment_passed_cancel_date'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_PASSED_CANCEL_DATE,
    },
    {
      label: t('validations.api.employee_economic_unemployment_has_older_absences'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_HAS_OLDER_ABSENCES,
    },
    {
      label: t('validations.api.employee_economic_unemployment_uploaded'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_UPLOADED,
    },
    {
      label: t('validations.api.employee_economic_unemployment_number_in_use'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_NUMBER_IN_USE,
    },
    {
      label: t('validations.api.employee_economic_unemployment_number_wrong_format'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_NUMBER_WRONG_FORMAT,
    },
    {
      label: t('validations.api.employee_economic_unemployment_multiple_days'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_MULTIPLE_DAYS,
    },
    {
      label: t('validations.api.employee_economic_unemployment_cannot_be_reuploaded'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_CANNOT_BE_REUPLOADED,
    },
    {
      label: t('validations.api.employee_economic_unemployment_has_unfinished_jobs'),
      value: ApiError.EMPLOYEE_ECONOMIC_UNEMPLOYMENT_HAS_UNFINISHED_JOBS,
    },
    {
      label: t('validations.api.performance_manual_funded_hours_too_high'),
      value: ApiError.PERFORMANCE_MANUAL_FUNDED_HOURS_TOO_HIGH,
    },
    {
      label: t('validations.api.domicile_not_france_french_border_worker'),
      value: ApiError.DOMICILE_NOT_FRANCE_FRENCH_BORDER_WORKER,
    },
    {
      label: t('validations.api.employee_department_in_past'),
      value: ApiError.EMPLOYEE_DEPARTMENT_IN_PAST,
    },
    {
      label: t('validations.api.tax_withholding_overlaps'),
      value: ApiError.TAX_WITHHOLDING_OVERLAPS,
    },
    {
      label: t('validations.api.address_history_first'),
      value: ApiError.ADDRESS_HISTORY_FIRST,
    },
    {
      label: t('validations.api.employee_department_has_one_in_future'),
      value: ApiError.EMPLOYEE_DEPARTMENT_HAS_ONE_IN_FUTURE,
    },
    {
      label: t('validations.api.absence_overlaps'),
      value: ApiError.ABSENCE_OVERLAPS,
    },
    {
      label: t('validations.api.absence_exclusive_for_union_representative'),
      value: ApiError.ABSENCE_EXCLUSIVE_FOR_UNION_REPRESENTATIVE,
    },
    {
      label: t('validations.api.absence_exclusive_for_in_notice'),
      value: ApiError.ABSENCE_EXCLUSIVE_FOR_IN_NOTICE,
    },
    {
      label: t('validations.api.absence_exclusive_for_at_least_half_time'),
      value: ApiError.ABSENCE_EXCLUSIVE_FOR_AT_LEAST_HALF_TIME,
    },
    {
      label: t('validations.api.advance_payment_not_wanted'),
      value: ApiError.ADVANCE_PAYMENT_NOT_WANTED,
    },
    {
      label: t('validations.api.department_contains_active_contracts'),
      value: ApiError.DEPARTMENT_CONTAINS_ACTIVE_CONTRACTS,
    },
    {
      label: t('validations.api.office_has_active_employees'),
      value: ApiError.OFFICE_HAS_ACTIVE_EMPLOYEES,
    },
    {
      label: t('validations.api.employee_has_no_contract_for_wage'),
      value: ApiError.EMPLOYEE_HAS_NO_CONTRACT_FOR_WAGE,
    },
    {
      label: t('validations.api.part_time_absences_exist_in_timetable'),
      value: ApiError.PART_TIME_ABSENCES_EXIST_IN_TIMETABLE,
    },
    {
      label: t('validations.api.available_hours_lower_than_preferred_part_time_hours'),
      value: ApiError.AVAILABLE_HOURS_LOWER_THAN_PREFERRED_PART_TIME_HOURS,
    },
    {
      label: t('validations.api.max_temporary_contracts_exceeded'),
      value: ApiError.MAX_TEMPORARY_CONTRACTS_EXCEEDED,
    },
    {
      label: t('validations.api.holiday_exists_on_date'),
      value: ApiError.HOLIDAY_EXISTS_ON_DATE,
    },
    {
      label: t('validations.api.counter_amount_too_high'),
      value: ApiError.COUNTER_AMOUNT_TOO_HIGH,
    },
    {
      label: t('validations.api.counter_not_present'),
      value: ApiError.COUNTER_NOT_PRESENT,
    },
    {
      label: t('validations.api.maximum_temporary_work_period_exceeded'),
      value: ApiError.MAXIMUM_TEMPORARY_WORK_PERIOD_EXCEEDED,
    },
    {
      label: t('validations.api.salary_has_negative_counter'),
      value: ApiError.SALARY_HAS_NEGATIVE_COUNTER,
    },
    {
      label: t('validations.api.illegal_or_bank_holiday_follow_up'),
      value: ApiError.ILLEGAL_OR_BANK_HOLIDAY_FOLLOW_UP,
    },
    {
      label: t('validations.api.no_email_given_for_delivery_method_email'),
      value: ApiError.NO_EMAIL_GIVEN_FOR_DELIVERY_METHOD_EMAIL,
    },
    {
      label: t('validations.api.confirmed_payments_department'),
      value: ApiError.CONFIRMED_PAYMENTS_DEPARTMENT,
    },
    {
      label: t('validations.api.update_economic_unemployment_absence_not_allowed'),
      value: ApiError.UPDATE_ECONOMIC_UNEMPLOYMENT_ABSENCE_NOT_ALLOWED,
    },
    {
      label: t('validations.api.performance_is_registered'),
      value: ApiError.PERFORMANCE_IS_REGISTERED,
    },
    {
      label: t('validations.api.unconfirmed_salaries'),
      value: ApiError.UNCONFIRMED_SALARIES,
    },
    {
      label: t('validations.api.salary_global_already_confirmed'),
      value: ApiError.SALARY_GLOBAL_ALREADY_CONFIRMED,
    },
    {
      label: t('validations.api.customer_does_not_own_payment_identity'),
      value: ApiError.CUSTOMER_DOES_NOT_OWN_PAYMENT_IDENTITY,
    },
    {
      label: t('validations.api.coordinates_not_present'),
      value: ApiError.COORDINATES_NOT_PRESENT,
    },
    {
      label: t('validations.api.partena_absence_code_already_used'),
      value: ApiError.PARTENA_ABSENCE_CODE_ALREADY_USED,
    },
    {
      label: t('validations.api.salary_calculating'),
      value: ApiError.SALARY_CALCULATING,
    },
    {
      label: t('validations.api.different_dimona_period_number_exists_for_contract'),
      value: ApiError.DIFFERENT_DIMONA_PERIOD_NUMBER_EXISTS_FOR_CONTRACT,
    },
    {
      label: t('validations.api.customer_needs_terms_of_service_acceptance'),
      value: ApiError.CUSTOMER_NEEDS_TERMS_OF_SERVICE_ACCEPTANCE,
    },
    {
      label: t('validations.api.counter_amount_exceeded'),
      value: ApiError.COUNTER_AMOUNT_EXCEEDED,
    },
    {
      label: t('validations.api.invalid_csv_row'),
      value: ApiError.INVALID_CSV_ROW,
    },
    {
      label: t('validations.api.unknown_merge_error'),
      value: ApiError.UNKNOWN_MERGE_ERROR,
    },
    {
      label: t('validations.api.not_all_payroll_offices_match'),
      value: ApiError.NOT_ALL_PAYROLL_OFFICES_MATCH,
    },
    {
      label: t('validations.api.payroll_already_merged'),
      value: ApiError.PAYROLL_ALREADY_MERGED,
    },
    {
      label: t('validations.api.payroll_merge_source_and_target_match'),
      value: ApiError.PAYROLL_MERGE_SOURCE_AND_TARGET_MATCH,
    },
    {
      label: t('validations.api.timetables_started_on_or_after_merge_exist'),
      value: ApiError.TIMETABLES_STARTED_ON_OR_AFTER_MERGE_EXIST,
    },
    {
      label: t('validations.api.department_merge_source_and_target_match'),
      value: ApiError.DEPARTMENT_MERGE_SOURCE_AND_TARGET_MATCH,
    },
    {
      label: t('validations.api.department_already_merged'),
      value: ApiError.DEPARTMENT_ALREADY_MERGED,
    },
    {
      label: t('validations.api.department_merge_sodexo_subsidiaries_do_not_match'),
      value: ApiError.DEPARTMENT_MERGE_SODEXO_SUBSIDIARIES_DO_NOT_MATCH,
    },
    {
      label: t('validations.api.department_merge_branch_payrolls_do_not_match'),
      value: ApiError.DEPARTMENT_MERGE_BRANCH_PAYROLLS_DO_NOT_MATCH,
    },
    {
      label: t('validations.api.department_merge_date_in_past'),
      value: ApiError.DEPARTMENT_MERGE_DATE_IN_PAST,
    },
  ]
})

export function getApiErrorLabel(error: string | ApiError): string | null {
  return API_ERROR_OPTIONS.value.find((option) => option.value === error)?.label || null
}
