import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum.ts'
import { permissionMiddleware } from '@/middleware/permission.middleware.ts'
import ROUTE_NAME from '@/router/routeName.ts'

export const logbooksRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.LOGBOOKS.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.LOGBOOK_READ,
        ]),
      ],
    },
    path: '/logbooks',
    component: () => import('@/modules/logbook/views/LogbookView.vue'),
  },
]
