import { useAuthStore } from '@/modules/authentication/stores/auth.store.ts'
import { MiddlewareUtil } from '@/plugins/router/middleware.util.ts'
import ROUTE_NAME from '@/router/routeName.ts'

export const guestMiddleware = MiddlewareUtil.createMiddleware(async () => {
  const authStore = useAuthStore()

  const isLoggedIn = await authStore.isLoggedIn()

  if (!isLoggedIn) {
    return
  }

  return {
    name: ROUTE_NAME.DASHBOARD,
  }
})
