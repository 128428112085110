import * as Sentry from '@sentry/vue'
import {
  useLocalStorage,
  useStorage,
} from '@vueuse/core'
import { defineStore } from 'pinia'
import { nextTick, ref } from 'vue'

import type { RecentSearch } from '@/components/layout/search-bar/the-search-bar/searchItem.type'
import { useLoading } from '@/composables/loading/loading.composable'
import { Permission } from '@/constants/permission.enum'
import { oAuthClient } from '@/http/oAuthClient'
import { UserBuilder } from '@/models/user.builder.ts'
import type { User } from '@/models/user.type'
import { AuthenticationService } from '@/modules/authentication/services/authentication.service.ts'
import { formatFullName } from '@/utils/name/name.util.ts'
import { CLEAR_IN_MERGE_VALUE } from '@/utils/pagination.util'

export const useAuthStore = defineStore('AuthStore', () => {
  const loadingState = useLoading()

  const recentSearches = useStorage<RecentSearch[]>('recentSearches', [])

  const authenticatedUser = ref<User | null>(null)

  const scannerIpAddress = useLocalStorage<string | null>('scannerIpAddress', null)
  const currentDepartmentUuid = useStorage<string | null>('currentDepartment', null)

  function hasPermission(permissions: Permission[] | undefined): boolean {
    if (!permissions) {
      return true
    }

    const userPermissions = authenticatedUser?.value?.staff?.role?.permissions ?? []

    if (userPermissions.length === 0) {
      return false
    }

    const permissionsToCheck = [
      ...permissions,
      Permission.ADMIN,
    ]

    return userPermissions.some((permission) => permissionsToCheck.includes(permission))
  }

  async function loginZitadel(code: string): Promise<void> {
    try {
      loadingState.setLoadingState(true)
      await oAuthClient.loginWithCode(code)
      await fetchAuthenticatedUserInfo()
    }
    finally {
      loadingState.setLoadingState(false)
    }
  }

  function setMockUser(): void {
    authenticatedUser.value = new UserBuilder().build()
  }

  async function fetchAuthenticatedUserInfo(): Promise<void> {
    const isLoggedIn = await oAuthClient.isLoggedIn()

    if (!isLoggedIn) {
      return
    }

    if (authenticatedUser.value !== null || loadingState.isLoading.value) {
      return
    }

    try {
      loadingState.setLoadingState(true)

      authenticatedUser.value = await AuthenticationService.getUserInfo()

      Sentry.setUser({
        id: authenticatedUser.value.uuid,
        email: authenticatedUser.value.staff.email,
        username: formatFullName(authenticatedUser.value.staff.firstName, authenticatedUser.value.staff.lastName),
      })
    }
    finally {
      loadingState.setLoadingState(false)
    }
  }

  function logout(): void {
    authenticatedUser.value = null
    oAuthClient.logout()
  }

  async function setCurrentDepartment(departmentUuid: string | null): Promise<void> {
    if (!departmentUuid) {
      await nextTick(() => {
        currentDepartmentUuid.value = CLEAR_IN_MERGE_VALUE
      })
      await nextTick(() => {
        currentDepartmentUuid.value = null
      })

      return
    }

    currentDepartmentUuid.value = departmentUuid
  }

  function setScannerIpAddress(ipAddress: string | null): void {
    scannerIpAddress.value = ipAddress
  }

  function addRecentSearch(recentSearch: RecentSearch): void {
    const recentSearchesValue = recentSearches.value
    const index = recentSearchesValue.findIndex((item) => item.uuid === recentSearch.uuid)

    if (index !== -1) {
      recentSearchesValue.splice(index, 1)
    }

    recentSearchesValue.unshift(recentSearch)
    recentSearches.value = recentSearchesValue
  }

  function clearRecentSearches(): void {
    recentSearches.value = []
  }

  return {
    hasPermission,
    isLoading: loadingState.isLoading,
    addRecentSearch,
    authenticatedUser,
    clearRecentSearches,
    currentDepartmentUuid,
    fetchAuthenticatedUserInfo,
    getLoginUrl,
    isLoggedIn,
    getLogoutUrl,
    loginZitadel,
    setMockUser,
    logout,
    recentSearches,
    scannerIpAddress,
    setCurrentDepartment,
    setScannerIpAddress,
  }
})

async function getLoginUrl(): Promise<string> {
  return await oAuthClient.getLoginUrl()
}

async function isLoggedIn(): Promise<boolean> {
  return await oAuthClient.isLoggedIn()
}

function getLogoutUrl(): string {
  return oAuthClient.getLogoutUrl()
}
