import { Language } from '@/constants/language.enum.ts'
import { Permission } from '@/constants/permission.enum.ts'
import { StaffType } from '@/constants/staffType.enum.ts'
import type { User } from '@/models/user.type.ts'
import { UuidUtil } from '@/utils/uuid.util.ts'

export class UserBuilder {
  value: User = {
    uuid: UuidUtil.getRandom<string>(),
    createdAt: new Date(),
    updatedAt: new Date(),
    locale: Language.NL,
    staff: {
      uuid: UuidUtil.getRandom(),
      departmentStaff: [],
      email: 'staff@email.com',
      firstName: 'First',
      lastName: 'Last',
      role: {
        uuid: UuidUtil.getRandom(),
        name: 'Admin',
        permissions: [
          Permission.ADMIN,
        ],
      },
    },
    type: StaffType.STAFF,
  }

  constructor() {
  }

  build(): User {
    return this.value
  }

  withCreatedAt(createdAt: Date): UserBuilder {
    this.value.createdAt = createdAt

    return this
  }

  withUpdatedAt(updatedAt: Date): UserBuilder {
    this.value.updatedAt = updatedAt

    return this
  }

  withUuid(uuid: string): UserBuilder {
    this.value.uuid = uuid

    return this
  }
}
