import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import { i18nPlugin } from '@/plugins/i18n.plugin'
import ROUTE_NAME from '@/router/routeName'

export const employeesRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.EMPLOYEES.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_READ,
        ]),
      ],
    },
    path: '/employees',
    component: () => import('@/modules/employees/views/EmployeeIndexView.vue'),
  },
  {
    name: ROUTE_NAME.EMPLOYEES.DETAIL.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_READ,
        ]),
      ],
    },
    path: '/employees/:uuid',
    component: () => import('@/modules/employees/views/detail/EmployeesDetailView.vue'),
    redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.INDEX },
    children: [
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.INDEX,
        path: 'overview',
        component: () => import('@/modules/employees/views/detail/overview/EmployeesDetailOverviewView.vue'),
        meta: {
          name: i18nPlugin.global.t('shared.employees'),
        },
        redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.DASHBOARD },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.DASHBOARD,
            path: 'dashboard',
            component: () =>
              import('@/modules/employees/views/detail/overview/EmployeesDetailOverviewDashboardView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.OVERVIEW.LOGBOOKS,
            meta: {
              middleware: [
                permissionMiddleware([
                  Permission.LOGBOOK_READ,
                ]),
              ],
            },
            path: 'logbook',
            component: () =>
              import('@/modules/employees/views/detail/overview/EmployeesDetailOverviewLogbooksView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.INDEX,
        path: 'planning',
        component: () => import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningView.vue'),
        redirect: {
          name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.CALENDAR,
        },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.CALENDAR,
            path: 'calendar',
            component: () =>
              import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningCalendarView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.AVAILABILITIES,
            path: 'availabilities',
            component: () =>
              import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningAvailabilitiesView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.PLANNING.CUSTOMERS,
            path: 'customers',
            component: () =>
              import('@/modules/employees/views/detail/planning/EmployeesDetailPlanningCustomersView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.INDEX,
        path: 'info',
        component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoView.vue'),
        redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PERSONAL },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PERSONAL,
            path: 'personal',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoPersonalView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PROFESSIONAL,
            path: 'professional',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoProfessionalView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.FISCAL,
            path: 'fiscal',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoFiscalView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.SUBSIDIES,
            path: 'subsidies',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoSubsidiesView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.APPLICATION,
            path: 'application',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoApplicationView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.LEAD,
            path: 'lead',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoLeadView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.PLUXEE_LOGIN,
            path: 'pluxee-login-login',
            component: () =>
              import('@/modules/employees/views/detail/info/EmployeesDetailInfoProfessionalPluxeeLoginView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.CANCELLATION,
            path: 'cancellation',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoCancellationView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.INFO.REJECTION,
            path: 'rejection',
            component: () => import('@/modules/employees/views/detail/info/EmployeesDetailInfoRejectionView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.INDEX,

        meta: {
          middleware: [
            permissionMiddleware([
              Permission.EMPLOYEE_CONTRACT_READ,

            ]),
          ],
        },
        path: 'contracts',
        redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.OVERVIEW },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.OVERVIEW,
            path: 'overview',
            component: () => import('@/modules/employees/views/detail/contracts/EmployeesDetailContractsView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.DETAIL,
            path: ':contractUuid',
            component: () =>
              import('@/modules/employees/views/detail/contracts/EmployeesDetailContractsDetailView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.DIMONA_DECLARATIONS_INDEX,
            path: ':contractUuid/declarations',
            component: () =>
              import(
                '@/modules/employees/views/detail/contracts/dimona-declarations/EmployeeDetailContractDimonaDeclarationView.vue'
              ),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.INDEX,
        path: 'compensation',
        component: () => import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationView.vue'),
        redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.WAGE },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.WAGE,
            path: 'wage',
            component: () =>
              import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationWageView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.WAGE_BENEFITS,
            path: 'wage-benefits',
            component: () =>
              import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationWageBenefitView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.ADVANCE,
            path: 'advance',
            component: () =>
              import(
                '@/modules/employees/views/detail/compensation/EmployeesDetailCompensationAdvancePaymentRejectionsView.vue'
              ),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.ECONOMIC_UNEMPLOYMENT,
            path: 'economic-unemployment',
            component: () =>
              import(
                '@/modules/employees/views/detail/compensation/EmployeesDetailCompensationEconomicUnemploymentView.vue'
              ),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.REFERRAL_PREMIUMS,
            path: 'referral_premiums',
            component: () =>
              import('@/modules/employees/views/detail/compensation/EmployeeDetailCompensationReferralPremiumView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.COMPENSATION.TAX_WITHHOLDING,
            path: 'tax-withholdings',
            component: () =>
              import('@/modules/employees/views/detail/compensation/EmployeesDetailCompensationTaxWithholdingView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.INDEX,
        path: 'absences',
        component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesView.vue'),
        redirect: { name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.ILLNESS },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.ILLNESS,
            path: 'illness',
            component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesIllnessView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.LEAVE,
            path: 'leave',
            component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesLeaveView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.PREGNANCY,
            path: 'pregnancy',
            component: () =>
              import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesPregnancyView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.ACCIDENTS,
            path: 'accidents',
            component: () =>
              import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesAccidentsView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.PART_TIME,
            path: 'part-time',
            component: () =>
              import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesPartTimeView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.OTHER,
            path: 'other',
            component: () => import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesOtherView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.COUNTERS,
            path: 'counters',
            component: () =>
              import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesCountersView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.ABSENCES.PART_TIME_UPDATE,
        path: 'part-time/:absenceUuid',
        component: () =>
          import('@/modules/employees/views/detail/absences/EmployeesDetailAbsencesPartTimeUpdateView.vue'),
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.DOCUMENTS.INDEX,
        path: 'documents',
        component: () => import('@/modules/employees/views/detail/documents/EmployeeDetailDocumentsView.vue'),
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.INDEX,
        path: 'notification',
        component: () => import('@/modules/employees/views/detail/notifications/EmployeesDetailNotificationsView.vue'),
        redirect: {
          name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
        },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.DAMAGE_CLAIMS,
            path: 'damage-claims',
            component: () =>
              import('@/modules/employees/views/detail/notifications/EmployeesDetailNotificationsClaimsView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.NOTIFICATIONS.WORK_ACCIDENTS,
            path: 'work-accidents',
            component: () =>
              import('@/modules/employees/views/detail/notifications/EmployeesDetailNotificationsAccidentsView.vue'),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.INDEX,
        meta: {
          middleware: [
            permissionMiddleware([
              Permission.CHEQUE_READ,
            ]),
          ],
        },
        path: 'service-checks',
        component: () => import('@/modules/employees/views/detail/service-checks/EmployeesDetailServiceChecksView.vue'),
        redirect: {
          name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.SHORTAGES.INDEX,
        },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.TREATABLE.INDEX,
            path: 'treatable',
            component: () =>
              import(
                '@/modules/employees/views/detail/service-checks/treatable/EmployeesDetailServiceChecksTreatableView.vue'
              ),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.SHORTAGES.INDEX,
            path: 'shortages',
            component: () =>
              import(
                '@/modules/employees/views/detail/service-checks/shortages/EmployeesDetailServiceChecksShortagesView.vue'
              ),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.SERVICE_CHECKS.CHECKS.INDEX,
            path: 'checks',
            component: () =>
              import(
                '@/modules/employees/views/detail/service-checks/checks/EmployeesDetailServiceChecksChecksView.vue'
              ),
          },
        ],
      },
      {
        name: ROUTE_NAME.EMPLOYEES.DETAIL.TO_DOS.INDEX,
        meta: {
          middleware: [
            permissionMiddleware([
              Permission.TODO_READ,
            ]),
          ],
        },
        path: 'to-dos',
        component: () => import('@/modules/employees/views/detail/todo/EmployeeDetailTodoView.vue'),
        redirect: {
          name: ROUTE_NAME.EMPLOYEES.DETAIL.TO_DOS.OPEN,
        },
        children: [
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.TO_DOS.OPEN,
            path: 'open',
            component: () => import('@/modules/employees/views/detail/todo/EmployeeDetailTodoOpenView.vue'),
          },
          {
            name: ROUTE_NAME.EMPLOYEES.DETAIL.TO_DOS.COMPLETED,
            path: 'completed',
            component: () => import('@/modules/employees/views/detail/todo/EmployeeDetailTodoCompletedView.vue'),
          },
        ],
      },
    ],
  },
  {
    name: ROUTE_NAME.EMPLOYEES.APPLICATION.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_READ,
          Permission.EMPLOYEE_CREATE,
        ]),
      ],
    },
    path: 'employees/:uuid/application',
    component: () => import('@/modules/employees/views/application/EmployeesApplicationView.vue'),
  },
  {
    name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.CONCEPT,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_CONTRACT_READ,
          Permission.EMPLOYEE_CONTRACT_CREATE,
          Permission.EMPLOYEE_CONTRACT_UPDATE,

        ]),
      ],
    },
    path: 'employees/:uuid/contract/concept',
    component: () => import('@/modules/employees/views/contract/EmployeesContractConceptView.vue'),
  },
  {
    name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.TIMETABLE_CORRECTION_CONCEPT,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_CONTRACT_READ,
        ]),
      ],
    },
    path: 'employees/:uuid/contract/:contractUuid/timetables/:timetableUuid/timetable-correction-concept',
    component: () => import('@/modules/employees/views/contract/EmployeesContractTimetableCorrectionConceptView.vue'),
  },
  {
    name: ROUTE_NAME.EMPLOYEES.DETAIL.CONTRACTS.TIMETABLE_CONCEPT,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.EMPLOYEE_CONTRACT_READ,
        ]),
      ],
    },
    path: 'employees/:uuid/contract/:contractUuid/timetable-concept',
    component: () => import('@/modules/employees/views/contract/EmployeesContractTimetableConceptView.vue'),
  },
]
