import { httpClient } from '@/http/httpClient.ts'
import type { InfoBubbleForm } from '@/modules/settings/models/info-bubbles/infoBubble.form'
import type { InfoBubbleItem } from '@/modules/settings/models/info-bubbles/infoBubble.type'

export default class InfoBubbleService {
  async create(infoBubbleForm: InfoBubbleForm): Promise<InfoBubbleItem> {
    return await httpClient.post('/info-bubbles', infoBubbleForm).then((response) => response.data)
  }

  async detail(infoBubbleUuid: string): Promise<InfoBubbleItem> {
    return await httpClient.get(`/info-bubbles/${infoBubbleUuid}`).then((response) => response.data)
  }

  async getAll(): Promise<InfoBubbleItem[]> {
    return await httpClient.get('/info-bubbles').then((response) => response.data)
  }

  async update(infoBubbleUuid: string, infoBubbleForm: InfoBubbleForm): Promise<InfoBubbleItem> {
    return await httpClient.post(`/info-bubbles/${infoBubbleUuid}`, infoBubbleForm).then((response) => response.data)
  }
}
