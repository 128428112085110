import type { RouteRecordRaw } from 'vue-router'

import { Permission } from '@/constants/permission.enum'
import { permissionMiddleware } from '@/middleware/permission.middleware'
import ROUTE_NAME from '@/router/routeName'

export const subsidiesRoutes: RouteRecordRaw[] = [
  {
    name: ROUTE_NAME.SUBSIDIES.INDEX,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.SUBSIDY_READ,
        ]),
      ],
    },
    path: '/subsidies',
    component: () => import('@/modules/subsidies/views/SubsidiesView.vue'),
  },
  {
    name: ROUTE_NAME.SUBSIDIES.DETAIL,
    meta: {
      middleware: [
        permissionMiddleware([
          Permission.SUBSIDY_READ,
        ]),
      ],
    },
    path: '/subsidies/:uuid',
    component: () => import('@/modules/subsidies/views/SubsidiesDetailView.vue'),
  },
]
