<script lang="ts" setup>
import { onMounted } from 'vue'
import {
  RouterView,
  useRoute,
  useRouter,
} from 'vue-router'

import AppLoadingCover from '@/components/app/loading-cover/AppLoadingCover.vue'
import AppRefreshPromptModal from '@/components/app/refresh-prompt/AppRefreshPromptModal.vue'
import { useModalContainer } from '@/composables/dynamicModal.composable'
import { usePrimeVueConfig } from '@/composables/primeVueConfig.composable'
import { useRefreshServiceWorker } from '@/composables/service-worker/useRefreshServiceWorker.composable'
import {
  CURRENT_ENVIRONMENT,
  logBuildInformation,
} from '@/constants/environment.constant.ts'
import { AuthenticationService } from '@/modules/authentication/services/authentication.service.ts'
import dayjs from '@/plugins/dayjs.plugin'
import ROUTE_NAME from '@/router/routeName'
import { useLanguageStore } from '@/stores/language.store'

const route = useRoute()
const router = useRouter()
const primeVueConfig = usePrimeVueConfig()

const languageStore = useLanguageStore()

const { modals } = useModalContainer()

const refreshServiceWorker = useRefreshServiceWorker()

function onRefreshServiceWorkerButtonClick(): void {
  refreshServiceWorker.refresh()
}

function onCancelRefreshServiceWorkerButtonClick(): void {
  refreshServiceWorker.cancel()
}

async function checkStatus(): Promise<void> {
  if (CURRENT_ENVIRONMENT === 'e2e') {
    return
  }

  try {
    await AuthenticationService.checkStatus()

    if (route.name === ROUTE_NAME.AUTH.MAINTENANCE) {
      await router.push({ name: ROUTE_NAME.DASHBOARD })
    }
  }
  catch {
    await router.push({ name: ROUTE_NAME.AUTH.MAINTENANCE })
  }
}

onMounted(() => {
  checkStatus()
  logBuildInformation()
  languageStore.initialize()
  primeVueConfig.setPrimeVueConfig()
  dayjs.locale('nl')
})
</script>

<template>
  <div class="relative h-screen w-screen overflow-hidden">
    <RouterView v-slot="{ Component }">
      <transition
        mode="in-out"
        name="fade"
      >
        <AppLoadingCover v-if="!Component" />
      </transition>
      <transition
        mode="in-out"
        name="fade"
      >
        <section v-if="Component">
          <component :is="Component" />

          <template
            v-for="modal in modals"
            :key="modal.id"
          >
            <component
              :is="modal.component"
              :is-open="modal.isOpen"
              v-bind="modal.attrs"
            />
          </template>
        </section>
      </transition>
    </RouterView>
    <AppRefreshPromptModal
      :is-open="refreshServiceWorker.needRefresh.value"
      @close="onCancelRefreshServiceWorkerButtonClick"
      @success="onRefreshServiceWorkerButtonClick"
    />
  </div>
</template>
