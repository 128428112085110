<script lang="ts" setup>
import { computed } from 'vue'

import { StyleBuilder } from '@/builders/style.builder'
import AppText from '@/components/app/text/AppText.vue'
import type { DefaultThemeColor } from '@/themes/default/colors/defaultThemeColors.type'

export interface AppCardProps {
  title?: string | null
  hasPadding?: boolean
  hasShadow?: boolean
  isClickable?: boolean
  borderColor?: DefaultThemeColor | null
  color?: DefaultThemeColor | null
  hoverColor?: DefaultThemeColor | null
}

const props = defineProps<AppCardProps>()

const appCardStyle = computed<string>(() => {
  return new StyleBuilder()
    .add('w-full p-4 rounded-lg')
    .addConditional(props.hasShadow, 'shadow-lg')
    .addConditional(props.isClickable, 'text-left')
    .addConditional(Boolean(props.hoverColor), `hover:border-${props.hoverColor}`)
    .addTertiary(Boolean(props.color), `bg-${props.color}`, 'bg-white')
    .addConditional(Boolean(props.borderColor), `border border-${props.borderColor}`)
    .build()
})
</script>

<template>
  <component
    :is="isClickable ? 'button' : 'div'"
    :class="appCardStyle"
  >
    <AppText
      v-if="title"
      :value="title"
      class="pb-2"
      font-weight="bold"
      size="lg"
    />
    <slot />
  </component>
</template>
