import { isAxiosError } from 'axios'

import { CURRENT_ENVIRONMENT } from '@/constants/environment.constant.ts'
import { useAuthStore } from '@/modules/authentication/stores/auth.store.ts'
import { MiddlewareUtil } from '@/plugins/router/middleware.util.ts'
import ROUTE_NAME from '@/router/routeName.ts'
import { logError } from '@/utils/logger.util.ts'

export const authMiddleware = MiddlewareUtil.createMiddleware(async () => {
  const authStore = useAuthStore()

  if (CURRENT_ENVIRONMENT === 'e2e') {
    authStore.setMockUser()

    return
  }

  const isLoggedIn = await authStore.isLoggedIn()

  if (!isLoggedIn) {
    return {
      name: ROUTE_NAME.AUTH.LOGIN,
    }
  }

  try {
    await authStore.fetchAuthenticatedUserInfo()
  }
  catch (error) {
    if (isAxiosError(error)) {
      authStore.logout()
    }

    logError(error)

    return {
      name: ROUTE_NAME.AUTH.LOGIN,
    }
  }
})
